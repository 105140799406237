<template>
  <v-hover v-slot="{ hover }">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ score }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action
          class="ma-0"
          @click="$emit('delete-score', score)"
      >
        <v-fade-transition>
          <v-btn icon v-if="hover">
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </v-fade-transition>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
export default {
  name: "SingleScore",
  props: {
    score: Number
  }
}
</script>

<style scoped>

</style>
