<template>
  <v-app>
    <v-app-bar
        app
        color="red"
    >
      <v-container>
        <v-app-bar-title>Dartzähler</v-app-bar-title>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
              sm="6"
              md="3"
              class="pt-14"
          >
            <v-slider
                label="Spieler"
                v-model.number="playerCount"
                min="1"
                max="8"
                thumb-label="always"
            ></v-slider>
          </v-col>
          <v-col
              sm="6"
              md="3"
              class="pt-14"
          >
            <v-text-field
                label="Zielpunkte"
                v-model.number="target"
                filled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container
          :fluid="playerCount > 5"
      >
        <v-row>
          <player-scores
              v-for="number in playerCount"
              :key="number"
              :target="target"
          />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import PlayerScores from "@/components/PlayerScores";

export default {
  components: {PlayerScores},
  data() {
    return {
      playerCount: 2,
      target: 301
    }
  }
}
</script>
