<template>
  <v-col>
    <v-sheet
        min-height="70vh"
        rounded="lg"
        class="pa-6"
    >
      <v-row
          :class="{'text-h5': Math.min(...scores) === 0}"
      >
        <v-col>Avg</v-col>
        <v-col>{{ average }}</v-col>
      </v-row>
      <v-list
          class="player-scores"
          v-if="scores.length"
      >
        <single-score
            v-for="(score, index) of scores"
            v-bind:key="index"
            :score="score"
            @delete-score="deleteScore($event)"
        />
      </v-list>
      <div
          v-else
          class="py-4 font-italic"
      >
        Noch keine Scores
      </div>

      <v-form
          v-if="Math.min(...scores) > 0"
      >
        <v-row
            align="center"
        >
          <v-col>
            <v-text-field
                v-model="newScore"
                :rules="scoreRules"
                label="Nächster Score"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="auto"
          >
            <v-btn
                @click.prevent="addScore"
                type="submit"
                :disabled="!newScore || newScore > Math.min(...scores) || newScore > target"
                icon
            >
              <v-icon
                  color="green"
              >
                mdi-plus-circle
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-col>
</template>

<script>
import SingleScore from "@/components/SingleScore";

export default {
  name: "PlayerScores",
  components: {SingleScore},
  props: {
    target: {
      type: Number,
      default: 301
    },
  },
  data() {
    return {
      scores: [],
      newScore: null,
      scoreRules: [
        v => v <= Math.min(...this.scores) || 'Ungültiger Score',
        v => v <= this.target || 'Ungültiger Score',
      ]
    }
  },
  computed: {
    average() {
      if (!this.scores) return 0;

      if (this.scores.length === 0) {
        return 0;
      }

      let throws = this.scores.length;
      let scored = this.target - this.scores[throws - 1];
      return new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: 2
      }).format(scored / throws);
    }
  }
  ,
  methods: {
    addScore() {
      this.scores.push(this.newScore);
      this.newScore = null;
    },
    deleteScore(score) {
      this.scores.splice(this.scores.indexOf(score), 1);
    }
  }
}
</script>

<style>
.player-scores {
  max-height: 55vh;
  overflow-y: auto;
}
</style>
